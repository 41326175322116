import { Injectable, OnDestroy } from '@angular/core';
import { Router } from '@angular/router';
import { Observable, of, Subject } from 'rxjs';
import { catchError, map, takeUntil } from 'rxjs/operators';
import { AuthorizationService, ClientService, Service } from '@optimo/core';

@Injectable({
  providedIn: 'root',
})
export class CheckAuthGuard implements OnDestroy {
  private unsubscribe$ = new Subject<void>();
  constructor(
    public authService: AuthorizationService,
    private client: ClientService,
    public router: Router,
  ) {}
  canActivate(): boolean | Observable<boolean> | Promise<boolean> {
    return this.isAuthed;
  }

  canLoad(): boolean | Observable<boolean> | Promise<boolean> {
    return this.isAuthed;
  }
  get isAuthed() {
    return this.client
      .get('user/checkauth', { service: Service.Auth })
      .pipe(takeUntil(this.unsubscribe$))
      .pipe(
        map((_) => {
          return true;
        }),
        catchError((_) => {
          this.authService.logOut(true);
          return of(false);
        }),
      );
  }
  ngOnDestroy() {
    this.unsubscribe$.next();
    this.unsubscribe$.complete();
  }
}
