<div
  (click)="onToggleMenu($event)"
  class="header-profile ml-auto"
  [class.active]="isMenuVisible"
>
  <div class="avatar avatar-sm flex-shrink-0 line-h-normal">OP</div>

  <div class="pl-2 pr-1 font-size-14 font-color-dark font-family-bold">optimo</div>
  <app-icon
    icon="arrow-down-tbls"
    class="flex-shrink-0 d-none d-sm-inline mr-4 ml-auto"
  ></app-icon>
</div>
<div
  *ngIf="isMenuVisible"
  clickOutside
  (clickOutside)="onToggleMenu()"
  class="header-dropdown border rounded"
>
  <a
    routerLink="/profile"
    (click)="onToggleMenu()"
    class="border-bottom w-100 d-block p-3 text-decoration-none"
  >
    <app-icon icon="settings" class="icon-header-settings mr-3"></app-icon>
    <span class="font-size-14 font-family-medium text-dark">{{'PROFILE' | translate}}</span>
  </a>
  <div (click)="logout.emit()" class="p-20px cursor-pointer">
    <app-icon icon="logout" class="mr-3"></app-icon>
    <span class="font-size-14 font-family-medium text-danger">{{'SIGN_OUT' | translate}}</span>
  </div>
</div>
