import { Injectable, OnDestroy } from '@angular/core';
import { Subject } from 'rxjs';
// import { takeUntil, tap } from 'rxjs/operators';
import { MixpanelService } from './mixpanel.service';

@Injectable()
export class TrackingHelperService implements OnDestroy {
  private unsub$ = new Subject();
  private counterStore = new Map<string, { count: number }>();
  private eventStore: { eventName: string; data: unknown }[] = [];

  constructor(private mixpanel: MixpanelService) {
    // interval(1000)
    //   .pipe(
    //     takeUntil(this.unsub$),
    //     tap(() => {
    //       console.log('dev => countingEvents:', this.getCounters());
    //       console.log('dev => storingEvents:', this.getEvents());
    //     }),
    //   )
    //   .subscribe();
  }

  ngOnDestroy(): void {
    this.unsub$.next(true);
    this.unsub$.complete();
    this.resetAll();
  }

  pushEvent(eventName: string, data: unknown): void {
    this.eventStore.push({ eventName, data });
  }

  pushCounter(eventName: string, increment = 1): void {
    if (this.counterStore.has(eventName)) {
      this.counterStore.get(eventName).count += increment;
    } else {
      this.counterStore.set(eventName, { count: increment });
    }
  }

  getCounters(): { [key: string]: number } {
    const countersObj = {};
    this.counterStore.forEach((value, key) => (countersObj[key] = value.count));
    return countersObj;
  }

  getEvents(): { eventName: string; data: unknown }[] {
    return this.eventStore;
  }

  resetCounters(): void {
    this.counterStore.clear();
  }

  resetEvents(): void {
    this.eventStore = [];
  }

  resetAll(): void {
    this.resetCounters();
    this.resetEvents();
  }

  trackMixpanelEventWithData(
    mixpanelEventName: string,
    includeFromStore?: IncludeStoredEvents,
    additionalData?: object,
    keepDataAfterSubmit?: boolean,
  ): void {
    let payload = {};
    if (additionalData)
      payload = {
        ...additionalData,
      };

    if (
      includeFromStore === IncludeStoredEvents.Counter ||
      includeFromStore === IncludeStoredEvents.All
    ) {
      payload = {
        ...payload,
        ...this.getCounters(),
      };
    }

    if (
      includeFromStore === IncludeStoredEvents.Events ||
      includeFromStore === IncludeStoredEvents.All
    ) {
      payload = {
        ...payload,
        ...this.getEvents(),
      };
    }

    this.mixpanel.track(mixpanelEventName, payload);

    if (!keepDataAfterSubmit) this.resetAll();
  }
}

export enum IncludeStoredEvents {
  All = 0,
  Counter,
  Events,
  None,
}
