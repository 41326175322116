<div class="d-flex flex-column">
  <app-headbar
    (logout)="onLogout()"
    [sidebarComponent]="sidebar"
  ></app-headbar>
  <div class="authorized-layout">
    <div class="sidebar">
      <app-sidebar #sidebar></app-sidebar>
    </div>
    <div class="authorized-layout-content">
      <router-outlet></router-outlet>
    </div>
  </div>
</div>
