export enum ERoles {
  Odin = 1,
  Admin = 0,
  AfterSale = 5,
  Sale = 6,
  TechTeam = 7,
  Operator = 8,
  Call = 10,
}

export const ROLES = [
  { value: ERoles.Admin, label: 'Admin' },
  { value: ERoles.AfterSale, label: 'Aftersale' },
  { value: ERoles.Sale, label: 'Sale' },
  { value: ERoles.TechTeam, label: 'Tech. Team' },
  { value: ERoles.Operator, label: 'Operator' },
  { value: ERoles.Call, label: 'Call' },
];

export enum EPermissionCrud {
  Create,
  Read,
  Update,
  Delete,
}

export enum ERolesPermission {
  Admins,
  BusinessTypes,
  CatalogStockItemCategory,
  CatalogStockItems,
  DemoRequests,
  Devices,
  FAQCategories,
  FAQs,
  LegalEntities,
  Notifications,
  OfferApplications,
  Offers,
  RegistrationRequests,
  StockItemAttributeCategories,
  StockItemAttributes,
  StockItemAttributeValues,
  Supplier,
  SupplierUser,
  Uploads,
  Users,
  VideoTutorials,
  Downtime,
  ReleaseManager,
}

export interface IRolesPermission {
  Admins?: string[];
  BusinessTypes?: string[];
  CatalogStockItemCategory?: string[];
  CatalogStockItems?: string[];
  DemoRequests?: string[];
  Devices?: string[];
  FAQCategories?: string[];
  FAQs?: string[];
  LegalEntities?: string[];
  Notifications?: string[];
  OfferApplications?: string[];
  Offers?: string[];
  RegistrationRequests?: string[];
  StockItemAttributeCategories?: string[];
  StockItemAttributes?: string[];
  StockItemAttributeValues?: string[];
  Supplier?: string[];
  SupplierUser?: string[];
  Uploads?: string[];
  Users?: string[];
  VideoTutorials?: string[];
  Downtime?: string[];
}
