import { Component, Input, ChangeDetectionStrategy } from '@angular/core';
import { MenuItem } from './nested-menu.module';

@Component({
  selector: 'app-nested-menu',
  templateUrl: './nested-menu.component.html',
  styleUrls: ['./nested-menu.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class NestedMenuComponent {
  @Input()
  menu: MenuItem[];

  @Input()
  userDetails: any;

  @Input()
  hasGlovoIntegration: any;

  openUrl(menuItem: MenuItem) {
    // For Release Manager only
    if (menuItem?.link) {
      window.open(menuItem.link, '_blank');
    }
  }
}
