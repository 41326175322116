export interface IMixpanelUser {
  packageType: string;
  productType: string;
  legalEntityName: string;
  companyId: string;
  isAdapter: boolean;
  legalEntityStatus: string;
  legalEntityId: string;
  businessType: string;
}

export interface IMixpanelUserProps {
  fullName: string;
  isForTesting: boolean;
  $name: string;
}
