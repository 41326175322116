import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { StorageService } from '@optimo/core';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class AuthGuard {
  constructor(
    public router: Router,
    private storage: StorageService, // private rolePermission: RolePermissionService,
  ) {}

  canActivate(): boolean | Observable<boolean> | Promise<boolean> {
    return this.isAuthed;
  }

  canLoad(): boolean | Observable<boolean> | Promise<boolean> {
    return this.isAuthed;
  }

  private get isAuthed(): boolean {
    const accessToken = this.storage.getAccessToken();

    if (!accessToken) {
      this.goToLogin();
      return false;
    }
    // if (this.rolePermission.isPermissionsMapEmpty) {
    //   this.rolePermission.generateRolePermission(accessToken);
    // }
    return true;
  }

  private goToLogin() {
    this.router.navigate(['login']);
  }
}
