import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable, of, Subject } from 'rxjs';
import { filter, map } from 'rxjs/operators';

interface IconData {
  iconData: string;
  isFetching: boolean;
}

@Injectable({
  providedIn: 'root',
})
export class IconsService {
  private iconsMap = new Map<string, IconData>();

  private subscribeListener = new Subject<string>();

  constructor(private _http: HttpClient) {}

  getIcon(iconName: string): Observable<string> {
    let icon = this.iconsMap.get(iconName);
    if (!icon) {
      icon = {
        iconData: undefined,
        isFetching: true,
      };
      this.iconsMap.set(iconName, icon);
      this.getIconByName(iconName).subscribe(
        (svgStr) => {
          icon.iconData = svgStr;
          icon.isFetching = false;
          this.subscribeListener.next(iconName);
        },
        (error) => {
          console.error("Icon doesn't exist with name: " + iconName, error);
        },
      );
    }

    if (icon.isFetching) {
      return this.subscribeListener.pipe(
        filter((iconNameFromSub) => {
          return iconName === iconNameFromSub;
        }),
        map((_) => {
          return this.iconsMap.get(iconName).iconData;
        }),
      );
    } else {
      return of(icon.iconData);
    }
  }

  getIconByName(iconName: string): Observable<string> {
    const url = 'assets/icons/icon-' + iconName + '.svg';
    return this._http.get(url, { responseType: 'text' });
  }
}
