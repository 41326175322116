import { BrowserModule } from '@angular/platform-browser';
import { LOCALE_ID, NgModule } from '@angular/core';

import { CoreModule } from '@optimo/core';

import { AppComponent } from './app.component';
import { environment } from '../environments/environment';
import { AppRoutingModule } from './app-routing.module';
import { NotifierModule } from 'angular-notifier';
import {
  HttpClient,
  HttpClientModule,
  HTTP_INTERCEPTORS,
} from '@angular/common/http';
import { AuthorizedLayoutModule } from './layouts/authorized-layout/authorized-layout.module';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { FormsModule } from '@angular/forms';
import localeKa from '@angular/common/locales/ka';
import localeRu from '@angular/common/locales/ru';
import localeEn from '@angular/common/locales/en';
import { registerLocaleData } from '@angular/common';
import {
  MAT_TOOLTIP_DEFAULT_OPTIONS,
  MatTooltipModule,
} from '@angular/material/tooltip';
import { HttpErrorInterceptor } from './core/services/inteceptors/http_error_interceptor';
import { TranslateLoader, TranslateModule } from '@ngx-translate/core';
import { HttpLoaderFactory } from './core/factories/ngx-translate-factories';
import {
  currencyLabel,
  currencyFormat,
  currencyFormatShort,
  isUz,
} from '../config/ui-config';

registerLocaleData(localeKa);
registerLocaleData(localeRu);
registerLocaleData(localeEn);

@NgModule({
  declarations: [AppComponent],
  imports: [
    BrowserModule,
    CoreModule.forRoot({
      authRoute: environment.AUTH_ROUTE,
      mainRoute: environment.INVENTORY_ROUTE,
      cardRoute: environment.CARD_ROUTE,
      coreRoute: environment.CORE_ROUTE,
      billingRoute: environment.BILLING_ROUTE,
      auditRoute: environment.AUDIT_ROUTE,
      refreshAccessTokenEndpoint: 'User/RefreshAccessToken',
      isProduction: environment.production,
      gtagId: environment.GTAG_ID,
      appType: environment.APP_TYPE,
      paymentRoute: environment.PAYMENT_URL,
    }),
    AppRoutingModule,
    HttpClientModule,
    AuthorizedLayoutModule,
    NotifierModule,
    BrowserAnimationsModule,
    FormsModule,
    MatTooltipModule,
    TranslateModule.forRoot({
      loader: {
        provide: TranslateLoader,
        useFactory: HttpLoaderFactory,
        deps: [HttpClient],
      },
      defaultLanguage: isUz ? 'en' : 'ka',
    }),
  ],
  providers: [
    {
      provide: 'UI_CONFIG',
      useValue: { currencyLabel, currencyFormat, currencyFormatShort, isUz },
    },
    {
      provide: HTTP_INTERCEPTORS,
      useClass: HttpErrorInterceptor,
      multi: true,
    },
    { provide: LOCALE_ID, useValue: isUz ? 'en' : 'ka' },
    {
      provide: MAT_TOOLTIP_DEFAULT_OPTIONS,
      useValue: {
        showDelay: 0,
        hideDelay: 0,
        touchendHideDelay: 0,
        position: 'above',
        disableTooltipInteractivity: true,
      },
    },
  ],
  bootstrap: [AppComponent],
})
export class AppModule {}
