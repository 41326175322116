export enum ErrorCode {
  Unknown = 0,
  DefaultError = 1,
  ConcurrencyFailure = 2,
  PasswordMismatch = 3,
  InvalidToken = 4,
  LoginAlreadyAssociated = 5,
  InvalidUserName = 6,
  InvalidEmail = 7,
  DuplicateUserName = 8,
  DuplicateEmail = 9,
  InvalidRoleName = 10,
  DuplicateRoleName = 11,
  UserAlreadyHasPassword = 12,
  UserLockoutNotEnabled = 13,
  UserAlreadyInRole = 14,
  UserNotInRole = 15,
  PasswordTooShort = 16,
  PasswordRequiresNonAlphanumeric = 17,
  PasswordRequiresDigit = 18,
  PasswordRequiresLower = 19,
  PasswordRequiresUpper = 20,
  InvalidPhoneNumber = 21,
  InvalidCredentials = 22,
  RefreshTokenUsed = 23,
  UserExists = 24,
  UserVerificationPending = 25,
  RefreshTokenNotFound = 26,
  UserNotFound = 27,
  NotificationNotSent = 28,
  AlreadyRegisteredException = 29,
  UserLockedOut = 30,
  IdentificationNumberExists = 31,
  PasswordResetPendingException = 33,
  MultipleLegalEntitiesException = 34,
  NoActiveLegalEntitiesException = 35,
  LegalEntitySuspendedException = 36,
  ContractAgreementMissingException = 37,
  NotificationPhoneNumberRequired = 3000,
  NotificationOtpRequired = 3001,
  NotificationInvalidPhoneNumber = 3002,
  NotificationOtpLimitExhausted = 3003,
  NotificationOtpExpired = 3004,
  NotificationOtpUsed = 3005,
  NotificationWrongOtp = 3006,
  INNAlreadyExists = 99,
  PhoneNumberExists = 100,
  EmailExists = 101,
  BusinessTypeValidationError = 102,
  SupplierCreatedWithIssues = 103,
  WrongIban = 104,
}
