import { Directive, HostListener, Input } from '@angular/core';
import { MixpanelService } from '../services/mixpanel.service';

@Directive({
  selector: '[optimoMPClickTrack]',
})
export class MPClickTrackDirective {
  @Input() MPEventName: string;
  @Input() MPEventProp: object = {};

  constructor(private _mixpanelService: MixpanelService) {}

  @HostListener('click') onClick() {
    this._mixpanelService.track(this.MPEventName, this.MPEventProp);
  }
}
