import { Observable } from 'rxjs';

export interface INestedMenuItem {
  text: string;
  children?: this[];
  path?: string;
  checkGlovoIntegration?: boolean;
  icon?: string;
  decisionField?: string;
  alt?: string;
  isVisibleForAllLocations?: any;
  permission?: string;
  loadOnRequest?: Observable<boolean>;
  isForTesting?: boolean;
  link?: string;
  permissionName?: string;
}
