export const environment = {
  production: false,
  preProduction: false,
  AUTH_ROUTE: 'https://identity.dev.optimo.uz/V1/',
  INVENTORY_ROUTE: 'https://admin-api.dev.optimo.uz/api/v1/',
  CARD_ROUTE: 'https://card-api.dev.optimo.uz/v1/',
  CORE_ROUTE: 'https://core.dev.optimo.uz/api/v1/',
  BILLING_ROUTE: 'https://billing-api.dev.optimo.ge/api/v1/',
  AUDIT_ROUTE: 'https://audit-api.dev.optimo.ge/api/v1/',
  MIXPANEL_TOKEN: '',
  CDN_URL: 'https://staging-uploads.odin.techarea.ge/images/',
  GTAG_ID: '',
  APP_TYPE: 'Admin',
  PAYMENT_URL: 'https://payment-api.dev.optimo.ge/api/v1/',
  RELEASE_MANAGER: 'https://release-manager.dev.optimo.uz/login',
};
