<div class="header fixed-top">
  <app-icon
    class="header-burger d-inline-block d-md-none cursor-pointer"
    icon="burger"
    (click)="onBurgerClick()"
  ></app-icon>
  <div class="font-size-22 font-color-dark font-family-bold pl-4">
    <app-icon icon="back-dark" class="mr-3" (click)="backTo()" *ngIf="hasBackButton"></app-icon> {{title}}
  </div>
  <div class="d-flex align-items-center h-100">
    <app-headbar-menu
      class="h-100"
      (logout)="logout.emit()"
    ></app-headbar-menu>
  </div>
</div>
