export const ERROR_CODE = {
  DefaultError: 'დაფიქსირდა შეცდომა',
  ConcurrencyFailure: 'Concurrency Failure',
  PasswordMismatch: 'პაროლი არ ემთხვევა',
  InvalidToken: 'არასწორია ტოკენი',
  LoginAlreadyAssociated: 'Login Already Associated',
  InvalidUserName: 'მომხმარებელი არასწორია',
  InvalidEmail: 'ელ. ფოსტა არასწორია',
  DuplicateUserName: 'Duplicated User Name',
  DuplicateEmail: 'Duplicated Email',
  InvalidRoleName: 'Invalid Role Name',
  DuplicateRoleName: 'Duplicated Role Name',
  UserAlreadyHasPassword: 'User Already Has Password',
  UserLockoutNotEnabled: 'User Lockout Not Enabled',
  UserAlreadyInRole: 'User Already In Role',
  UserNotInRole: 'User Not In Role',
  PasswordTooShort: 'Password Too Short',
  PasswordRequiresNonAlphanumeric: 'Password Requires Non Alphanumeric',
  PasswordRequiresDigit: 'Password Requires Digit',
  PasswordRequiresLower: 'Password Requires Lower',
  PasswordRequiresUpper: 'Password Requires Upper',
  InvalidPhoneNumber: 'ტელეფონის ნომერი არასწორია',
  InvalidCredentials: 'ERROR_MESSAGE.INVALID_CREDENTIALS',
  NoActiveLegalEntitiesException: 'NoActiveLegalEntitiesException',
  LegalEntitySuspendedException: 'LegalEntitySuspendedException ',
  RefreshTokenUsed: 'Refresh Token Used',
  UserExists: 'მომხმარებელი უკვე არსებობს',
  UserVerificationPending: 'ვერიფიკაცია პროცესშია',
  RefreshTokenNotFound: 'Refresh Token Not Found',
  UserNotFound: 'მომხმარებელი არ მოიძებნა',
  AlreadyRegisteredException: 'Already Registered Exception',
  UserLockedOut: 'ERROR_MESSAGE.USER_LOCKED_OUT',
  IdentificationNumberExists: 'საიდენტიფიკაციო ნომერი უკვე არსებობს',

  NotificationNotSent: 'Notification Not Sent',
  NotificationPhoneNumberRequired: 'ტელეფონის ნომერი აუცილებელია',
  NotificationOtpRequired: 'ერთჯერადი პაროლი აუცილებელია',
  NotificationInvalidPhoneNumber: 'Invalid PhoneNumber',
  NotificationOtpLimitExhausted: 'გთხოვთ დაელოდეთ',
  NotificationOtpExpired: 'ერთჯერად პაროლს გაუვიდა ვადა',
  NotificationOtpUsed: 'ერთჯერადი პაროლი უკვე გამოყენებულია',
  NotificationWrongOtp: 'ერთჯერადი პაროლი არასწორია',
};
