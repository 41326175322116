import { Injectable, Injector } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { NotifierService } from 'angular-notifier';

@Injectable({
  providedIn: 'root',
})
export class NotificationsService {
  constructor(
    private notifier: NotifierService,
    private readonly injector: Injector,
  ) {}

  private get translateService(): TranslateService {
    const translateService = this.injector.get(TranslateService);
    return translateService;
  }

  sayDefault(defaultMsg: string): void {
    this.notifier.notify('default', this.translateService.instant(defaultMsg));
  }

  saySuccess(successMessage: string): void {
    this.notifier.notify(
      'success',
      this.translateService.instant(successMessage),
    );
  }

  sayError(error: string): void {
    this.notifier.notify('error', this.translateService.instant(error));
  }

  sayWarning(warning: string): void {
    this.notifier.notify('warning', this.translateService.instant(warning));
  }

  sayInfo(info: string): void {
    this.notifier.notify('info', this.translateService.instant(info));
  }
}
