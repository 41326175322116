import { ERolesPermission } from '../app/core/enums/roles.enum';
import { CustomValidators } from '../../../dashboard/src/app/core/helpers/validators/validators.helper';
import { environment } from '../environments/environment';

export const isGe = false;
export const isUz = true;

export const currencyLabel = 'UZS';
export const currencyFormatDigits = 2;
export const currencyFormat = `1.${currencyFormatDigits}-${currencyFormatDigits}`;
export const currencyFormatDigitsShort = 0;
export const currencyFormatShort = `1.${currencyFormatDigitsShort}-${currencyFormatDigitsShort}`;

export const phoneNumberMinLength = 9;
export const phoneNumberMaxLength = 9;
export const phoneNumberValidator = CustomValidators.UzPhoneNumber;
export const IdMaxLength = 9;
export const MENUTREE = [
  {
    path: '/home',
    text: 'Home',
    icon: 'main-admin',
  },
  {
    path: '/users',
    icon: 'users-admin',
    text: 'Users',
    permission: ERolesPermission.Users,
  },
  {
    icon: 'companies-admin',
    text: 'Legal_Entities',
    path: '/legal-entities',
    permission: ERolesPermission.LegalEntities,
  },
  {
    icon: 'billing',
    text: 'BILLING_TITLE',
    children: [
      {
        text: 'BILLING_PACKAGES',
        path: '/billing-packages',
        permission: ERolesPermission.Admins,
      },
      {
        text: 'BILLING_COMPANIES',
        path: '/billing-companies',
      },
      {
        text: 'BILLING_INVOICES',
        path: '/billing-invoices',
      },
      {
        text: 'BILLING_TRANSACTIONS',
        path: '/billing-transactions',
      },
      {
        text: 'BILLING_PACKAGE_CHANGES',
        path: '/billing-packages-changes-report',
      },
    ],
  },
  {
    path: '/devices',
    icon: 'devices-admin',
    text: 'Devices',
    permission: ERolesPermission.Devices,
  },
  {
    path: '/demo-requests',
    icon: 'demos-admin',
    text: 'Demo_Requests',
    permission: ERolesPermission.DemoRequests,
  },
  {
    path: '/business-types',
    icon: 'businesses-types-admin',
    text: 'Businesses_Types',
    permission: ERolesPermission.BusinessTypes,
  },
  {
    text: 'FAQ',
    icon: 'faq-admin',
    permission: ERolesPermission.FAQCategories,
    children: [
      {
        text: 'Faq_Categories',
        path: '/faq-categories',
        permission: ERolesPermission.FAQCategories,
      },
      {
        text: 'Faq',
        path: '/faqs',
        permission: ERolesPermission.FAQs,
      },
    ],
  },
  {
    text: 'Attributes',
    icon: 'attributes-admin',
    permission: ERolesPermission.StockItemAttributeCategories,
    children: [
      {
        text: 'Categories',
        path: '/attribute-categories',
        permission: ERolesPermission.StockItemAttributeCategories,
      },
      {
        text: 'Attributes',
        path: '/attributes',
        permission: ERolesPermission.StockItemAttributes,
      },
    ],
  },
  {
    text: 'Integration',
    icon: 'integration',
    children: [
      {
        text: 'Woocommerce',
        path: '/woocommerce',
      },
    ],
  },
  {
    path: '/notifications',
    icon: 'notification-admin',
    text: 'Notification',
    permission: ERolesPermission.Notifications,
  },
  {
    path: '/admins',
    icon: 'admins',
    text: 'Admins',
    permission: ERolesPermission.Admins,
  },
  {
    path: '/downtime',
    icon: 'admins',
    text: 'Downtime',
    permission: ERolesPermission.Downtime,
  },
  {
    icon: 'admins',
    text: 'ReleaseManager',
    permission: ERolesPermission.ReleaseManager,
    link: environment.RELEASE_MANAGER,
    path: '/release-manager',
  },
];
