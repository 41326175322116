import { Injectable } from '@angular/core';
import decode from 'jwt-decode';
import { IRolesPermission } from '../../../../../apps/admin-panel/src/app/core/enums/roles.enum';

@Injectable({
  providedIn: 'root',
})
export class RolePermissionService {
  private permissionsMap: IRolesPermission = {};

  deleteRolePermission(): void {
    this.permissionsMap = {};
  }

  generateRolePermission(token: string): void {
    const userData = decode(token);
    userData['AdminPermission'].map((permission) => {
      const key = permission.split('.')[0];
      const value = permission.split('.')[1];
      this.permissionsMap[key] = this.permissionsMap[key]
        ? [...this.permissionsMap[key], value]
        : [value];
    });
  }

  getRolePermissionMap(): IRolesPermission {
    return this.permissionsMap;
  }

  get isPermissionsMapEmpty(): boolean {
    return Object.keys(this.permissionsMap).length === 0;
  }
}
