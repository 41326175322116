import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { StorageService } from '@optimo/core';

@Injectable({
  providedIn: 'root',
})
export class UnauthGuard {
  constructor(private router: Router, private storage: StorageService) {}

  canActivate() {
    const accessToken = this.storage.getAccessToken();
    if (!accessToken) {
      return true;
    }
    this.router.navigate(['/']);
    return false;
  }
}
