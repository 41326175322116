/* eslint-disable @typescript-eslint/no-unused-vars */
import { Injectable } from '@angular/core';
import mixpanel from 'mixpanel-browser';
import { IMixpanelUser, IMixpanelUserProps } from '../models/IMixpanelUser';

@Injectable({
  providedIn: 'root',
})
export class MixpanelService {
  private _user: IMixpanelUser;
  private _token: string;
  private _trackingDisabled: boolean;

  disbaleTracking(): void {
    this._trackingDisabled = true;
  }

  private trackingIsDisabled() {
    return this._trackingDisabled;
  }

  public setToken(token: string): void {
    if (!token) {
      this.disbaleTracking();
      return;
    }
    this._token = token;
    mixpanel.init(this._token, {
      loaded: () => {
        if (!this._user) {
          this.reset();
        }
      },
    });
  }

  public identify(
    user: IMixpanelUser,
    userProps: IMixpanelUserProps,
    userIdentifier: string,
  ): void {
    if (!this._token) return;
    this._user = user;
    if (this.trackingIsDisabled()) return;
    mixpanel.identify(user.legalEntityId ?? 'no-legal-entity');
    mixpanel.people.set({
      $name: user.legalEntityName,
      ...user,
    });
  }

  public track(eventName: string, payload: object = {}): void {
    if (this.trackingIsDisabled() || !this._user || !this._token) return;
    setTimeout(() => {
      mixpanel.track(eventName, payload);
    }, 1000);
  }

  public reset(): void {
    if (!this._token) return;
    this._user = undefined;
    mixpanel.reset();
  }

  public trackPageView(pageName: string): void {
    if (!this._token) return;
    this.track(pageName);
  }
}
