import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import {
  HttpEvent,
  HttpInterceptor,
  HttpHandler,
  HttpRequest,
  HttpErrorResponse,
} from '@angular/common/http';
import { catchError } from 'rxjs/operators';
import { NotificationsService } from '../notifications/notifications.service';
import { ErrorCodeDetectorService } from '../error-code-detector/error-code-detector.service';
import { Observable, of } from 'rxjs';
import { MatDialog } from '@angular/material/dialog';
import { MatBottomSheet } from '@angular/material/bottom-sheet';
import { ErrorCode } from '../../enums/error-codes.enum';
import { ECommandValidationErrorCode } from '../../enums/ECommandValidationErrorCode';
import { ApproveDialogComponent } from '@optimo/ui-popups-approve-dialog';

@Injectable({
  providedIn: 'root',
})
export class HttpErrorInterceptor implements HttpInterceptor {
  constructor(
    private router: Router,
    private notificator: NotificationsService,
    private errorCodeDetector: ErrorCodeDetectorService,
    private dialog: MatDialog,
    private bottomSeet: MatBottomSheet,
  ) {}
  intercept(req: HttpRequest<any>, next: HttpHandler) {
    return this.handle(req, next);
  }

  private handle(
    req: HttpRequest<any>,
    next: HttpHandler,
  ): Observable<HttpEvent<any>> {
    if (req.headers.get('skip')) return next.handle(req);

    return next.handle(req).pipe(
      catchError((err) => {
        this.checkError(err);
        return of(null);
      }),
    );
  }

  private checkError(err: HttpErrorResponse) {
    switch (err.status) {
      case 400:
        if (this.isRefreshRequest(err.url)) {
          this.onLogout();
        } else {
          this.checkErrorCode(err);
        }
        break;
      case 403:
        this.showForbiddenMessage();
        break;
      case 413:
        this.showTooLargeEntryMessage();
        break;
      case 500:
        this.showRequestErrorMessage();
        break;
    }
    throw err;
  }

  private onLogout(): void {
    this.dialog.closeAll();
    this.bottomSeet.dismiss();
    this.router.navigate(['/login']);
  }

  private checkErrorCode(err: HttpErrorResponse) {
    if (this.hasCode(err)) {
      this.showErrorMessage(err);
    } else if (this.hasDomainErrors(err)) {
      this.showDomainError(err);
    } else {
      this.showDefaultMessage();
    }
  }

  private showDomainError(err: HttpErrorResponse) {
    if (
      err.error.Errors?.DomainErrorCodes.includes(
        ECommandValidationErrorCode.DuplicatedCategoryName,
      ) ||
      err.error.Errors?.DomainErrorCodes.includes(
        ECommandValidationErrorCode.ShiftIsNotClosed,
      )
    ) {
      return;
    } else {
      this.showDefaultMessage();
    }
  }

  private hasDomainErrors(err: HttpErrorResponse) {
    return err.error.Errors?.DomainErrorCodes?.length;
  }

  private hasCode(err: HttpErrorResponse) {
    return err.error && err.error.errorCode !== undefined;
  }

  private showErrorMessage(err: HttpErrorResponse) {
    if (
      err.error.errorCode === ErrorCode.PasswordResetPendingException ||
      err.error.errorCode === ErrorCode.MultipleLegalEntitiesException ||
      err.error.errorCode === ErrorCode.ContractAgreementMissingException
    ) {
      return;
    }
    const errorMessage = this.errorCodeDetector.getErrorMessage(
      err.error.errorCode,
    );
    if (errorMessage) {
      this.notificator.sayError(errorMessage);
    }
  }

  private showDefaultMessage() {
    this.notificator.sayError('DEFAULT_ERROR');
  }

  private showRequestErrorMessage() {
    this.notificator.sayError('DEFAULT_ERROR');
  }

  private showForbiddenMessage() {
    // this.notificator.sayError('FORBIDDEN');
    this.showApprovePopup();
  }

  private showTooLargeEntryMessage() {
    this.notificator.sayError('Request Entity Too Large.');
  }

  private isRefreshRequest(url: string) {
    return url.includes('RefreshAccessToken');
  }

  private showApprovePopup(): void {
    this.dialog
      .open(ApproveDialogComponent, {
        width: '575px',
        panelClass: ['mat-w-90pct-u-custsm'],
        data: {
          title: 'Access_Restriction',
          message: 'You_Do_Not_Have_Access',
          approveBtnLabel: 'CLOSE',
          isForbiddenPopup: true,
        },
      })
      .afterClosed()
      .subscribe(() => {
        this.router.navigate(['/']);
      });
  }
}
