<div class="sidebar sidebar-admin" [class.active]="active">
  <div class="sidebar-header align-items-center overflow-hidden">
    <app-icon
      icon="burger-admin"
      (click)="onToggleActive()"
      class="mr-30px d-md-none mt-14px ml-3 cursor-pointer pl-3"
    ></app-icon>

    <app-icon
      class="d-lg-inline d-none mr-30px cursor-pointer pl-3"
      icon="burger-admin"
      (click)="onToggleFold()"
    ></app-icon>
    <app-icon
      class="d-md-inline d-lg-none d-none mr-30px cursor-pointer pl-3"
      icon="burger-admin"
      (click)="onToggleFoldMedium()"
    ></app-icon>

    <a routerLink="/" class="router-link-active d-none d-md-block"
      ><img src="/assets/images/logo/logo-admin.svg" alt=""
    /></a>
  
  </div>
  <div class="sidebar-body">
    <app-nested-menu
    [menu]="MENU_TREE"
  ></app-nested-menu>
  </div>
  
</div>
