import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { MPClickTrackDirective } from './directives/mpclick-track.directive';

@NgModule({
  imports: [CommonModule],
  declarations: [MPClickTrackDirective],
  exports: [MPClickTrackDirective],
})
export class MixpanelModule {}
