<div class="nested-menu">
  <ng-container
    *ngFor="let menuItem of menu"
  >
  <app-menu-item
    *ngIf='menuItem?.loadOnRequest ? (menuItem?.loadOnRequest | async) : true'
    [item]="menuItem"
    [level]="1"
    [hasGlovoIntegration]="this.hasGlovoIntegration"
    [userDetails]="userDetails"
    (click)="openUrl(menuItem)"
  ></app-menu-item>
  </ng-container>
</div>
