import {
  Component,
  ElementRef,
  Inject,
  Input,
  OnDestroy,
  OnInit,
} from '@angular/core';
import { DOCUMENT } from '@angular/common';
import { IconsService } from '../../../../core/src/lib/services/icons.service';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';

@Component({
  selector: 'app-icon',
  templateUrl: './icon.component.html',
})
export class IconComponent implements OnInit, OnDestroy {
  private $unsubscribe = new Subject<void>();

  private _fill: string;

  @Input()
  set fill(value: string) {
    this._fill = value;
  }

  get fill(): string {
    return this._fill;
  }

  @Input()
  icon: string;

  constructor(
    @Inject(DOCUMENT) private document: any,
    private _elementRef: ElementRef,
    private iconsService: IconsService,
  ) {}

  private _svgElementFromString(str: string): SVGElement {
    const div = this.document.createElement('DIV');
    div.innerHTML = str as unknown as string;
    const svg = div.querySelector('svg') as SVGElement;

    // TODO: add an ngDevMode check
    if (!svg) {
      throw Error('<svg> tag not found');
    }

    const icon = this.document.createElement('span');
    icon.classList = 'app-icon-' + this.icon;
    icon.append(svg);
    return icon;
  }

  ngOnInit() {
    this.iconsService
      .getIcon(this.icon)
      .pipe(takeUntil(this.$unsubscribe))
      .subscribe((svgString: string) => {
        this._elementRef.nativeElement.appendChild(
          this._svgElementFromString(svgString),
        );
      });
  }

  ngOnDestroy(): void {
    this.$unsubscribe.next();
    this.$unsubscribe.complete();
  }
}
