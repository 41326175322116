<div class="approve-dialog" [ngClass]="data.classes">
  <div class="d-flex align-items-center justify-content-between pl-sm-4 pl-3 pr-sm-18px pr-2 border-bottom border-table-border-color h-60px">
    <div  class="font-size-sm-18 font-size-16 font-family-bold font-color-dark">
      <ng-container *ngIf="data?.title">
        {{ data.title | translate}}
      </ng-container>
    </div>
    <button
      class="btn bg-transparent rounded-0 border-0 p-6px d-flex justify-content-center align-items-center"
      (click)="onClose()"
    >
      <app-icon icon="close"></app-icon>
    </button>
  </div>
  <div
    *ngIf="data?.message"
    class="approve-dialog-text px-sm-4 px-3 pt-4 line-h-normal"
    [ngClass]="{ 'font-size-14': data.isForbiddenPopup, 'text-center' : data?.innerTitle }"
  >
    <ng-container *ngIf="data?.icon">
      <app-icon icon="alert-price-change"  class="mr-2"></app-icon>
    </ng-container>
    <ng-container *ngIf="data?.innerTitle">
      <div class="font-size-20 mb-3 text-center font-color-dark font-family-bold">{{ data.innerTitle  | translate}}</div>
    </ng-container>
     <span>{{ data.message  | translate}}</span>
  </div>


  <div
    *ngIf="data?.sanitizedMessage"
    class="approve-dialog-text px-sm-4 px-3 pt-4 line-h-normal"

  >
    <ng-container *ngIf="data?.icon">
      <app-icon icon="alert-price-change"  class="mr-2"></app-icon>
    </ng-container>

    <div [innerHTML]="data.sanitizedMessage">
      {{ data.sanitizedMessage  | translate}}
    </div>

  </div>
  <ng-container [ngTemplateOutlet]="data?.templateRef"></ng-container>

  <div class="action-buttons d-flex px-sm-4 px-3 py-4 justify-content-end" *ngIf='!data.hideButtons' [ngClass]="{  'justify-content-center' : data?.innerTitle }">
    <button
      class="btn bg-transparent border-0 px-4 font-size-14 font-color-dark font-family-bold h-42px mr-2 cancel-btn py-0"
      (click)="onDecline()"
      *ngIf="!data.isForbiddenPopup"
    >
    {{ data.denyBtnLabel || 'GENERAL.CANCEL'  | translate}}
    </button>
    <button
      class="btn btn-primary font-color-white px-4 font-size-14 font-family-bold h-42px min-w-95px approve-btn py-0"
      (click)="onApprove()"
    >
    {{ data.approveBtnLabel || 'GENERAL.DELETE' | translate}}
    </button>

  </div>

</div>
