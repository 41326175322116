import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { UnauthGuard } from './core/services/guards/unauth.guard';
import { AuthGuard } from './core/services/guards/auth.guard';
import { AuthorizedLayoutComponent } from './layouts/authorized-layout/authorized-layout.component';
import { CheckAuthGuard } from './core/services/guards/check-auth.guard';

const routes: Routes = [
  {
    path: 'login',
    canActivate: [UnauthGuard],
    loadChildren: () =>
      import('./pages/login/login.module').then((m) => m.LoginModule),
  },
  {
    path: 'access-token/:accessToken',
    canActivate: [UnauthGuard],
    loadChildren: () =>
      import('./pages/login/login.module').then((m) => m.LoginModule),
  },
  {
    path: 'print-barcode',
    canLoad: [AuthGuard],
    canActivate: [AuthGuard],
    loadChildren: () =>
      import('./pages/print-barcode/print-barcode.module').then(
        (m) => m.PrintBarcodeModule,
      ),
  },
  {
    path: 'invoice/:id',
    canLoad: [AuthGuard],
    canActivate: [AuthGuard],
    loadChildren: () =>
      import('libs/ui/billing-invoice-pdf/src/index').then(
        (m) => m.UiBillingInvoicePdfModule,
      ),
  },
  {
    path: '',
    component: AuthorizedLayoutComponent,
    canLoad: [CheckAuthGuard, AuthGuard],
    canActivate: [CheckAuthGuard, AuthGuard],
    loadChildren: () =>
      import('./pages/auth/auth.module').then((m) => m.AuthModule),
  },
  { path: '**', redirectTo: '', pathMatch: 'full' },
];

@NgModule({
  imports: [RouterModule.forRoot(routes, { onSameUrlNavigation: 'reload' })],
  exports: [RouterModule],
})
export class AppRoutingModule {}
