<ng-container *ngIf="(item.isVisibleForAllLocations || locationId) && isGlovoIntegration(item)">
    <ng-container
      *ngIf="
        (!item.decisionField || (userDetails && userDetails[item.decisionField]))
      "
    >
      <div
        class="sidebar-menu-item"
        [class.active-hover]="mouseIsOver ? true : false"
        (mouseover)="mouseOverItem.next(true)"
        (mouseleave)="mouseOverItem.next(false)"
        [class.has-route]="item.path"
        *ngIf="hasPermission(item)"
      >
        <a
          *ngIf="item.path || item.link"
          [class.has-route]="item.path"
          [routerLink]="item.path ? item.path : null"
          routerLinkActive="active"
          [matTooltip]="item.alt"
          matTooltipPosition="right"
          matTooltipClass="mat-tooltip-position-right"
          class="nested-menu-item nested-menu-link"
          [id]="'menu_item' + item?.path.toString().split('/').join('_')"
        >
        <div class="mr-2 w-24px" *ngIf="item.icon">
          <app-icon [icon]="item.icon" class="item-icon nested-menu-icon"></app-icon>
        </div>
          <span  class="ml-2 py-1 text-truncate">
            {{ item.text | translate }}
          </span>
        </a>

        <ng-container *ngIf="!item.path && !item.link">
          <div
            [matTooltip]="item.alt"
            matTooltipPosition="right"
            matTooltipClass="mat-tooltip-position-right"
            class="nested-menu-item nested-menu-link"
            (click)="toggleSubNav()"
            [class.navigated-to-child]="navigatedToChild"
            [id]="'menu_item_' +(item.text.toLowerCase().toString().split('.').join('_'))"
            *ngIf="hasPermission(item)"
          >
          <div class="mr-2 w-24px" *ngIf="item.icon">
            <app-icon [icon]="item.icon" class="nested-menu-icon"></app-icon>
          </div>
            <span class="ml-2 text-nowrap">
              {{ item.text | translate }}
            </span>
            <app-icon
              [icon]="'arrow-down-sidebar'"
              class="ml-auto text-nowrap icon-arrow-down-sidebar-admin icon-arrow-down-sidebar"
              [class.active]="active"
            ></app-icon>
          </div>
        </ng-container>
      </div>

      <ng-container *ngIf="item.children?.length > 0">
        <div class="subnav-wrapper"
          (mouseover)="mouseOverItem.next(true)"
          (mouseleave)="mouseOverItem.next(false)"
          [class.active-hover]="mouseIsOver ? true : false"
          [class.active]="active"
          (click)="mouseOverItem.next(false)"
             [id]="'menu_item_' +(item.text.toLowerCase().toString().split('.').join('_'))"
        >
          <div
          class="subnav"

          >
          <span class="subnav-menu-heading d-none">
            {{ item.text | translate }}
          </span>
          <ng-container
            *ngFor="let child of item.children"
          >
          <app-menu-item
            *ngIf='child?.loadOnRequest ? (child?.loadOnRequest | async) : true'
            [item]="child"
            [level]="level + 1"
          ></app-menu-item>
          </ng-container>
          </div>
        </div>

      </ng-container>
    </ng-container>
</ng-container>
