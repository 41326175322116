export function filterNavsByRoles(
  navigation: any[],
  acceptedUserRoles: string,
) {
  return navigation.filter((nav) => {
    if (nav.children) {
      nav.children = filterNavsByRoles(nav.children, acceptedUserRoles);
    }

    if (!nav?.allowedRoles) return true;
    return nav.allowedRoles.includes(acceptedUserRoles);
  });
}
