import { ChangeDetectionStrategy, Component, OnDestroy } from '@angular/core';
import { Subject } from 'rxjs';
import { AuthorizationService } from '@optimo/core';

@Component({
  selector: 'app-authorized-layout',
  templateUrl: './authorized-layout.component.html',
  styleUrls: ['./authorized-layout.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class AuthorizedLayoutComponent implements OnDestroy {
  pageLoading: boolean;
  userDetails: any;
  private unsubscribe$ = new Subject<void>();

  constructor(public authService: AuthorizationService) {}

  onLogout(navigateToLogin = true): void {
    this.authService.logOut(navigateToLogin);
  }

  ngOnDestroy(): void {
    this.unsubscribe$.next();
    this.unsubscribe$.complete();
  }
}
