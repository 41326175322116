import { Injectable } from '@angular/core';
import { StorageService } from './storage.service';
import { ClientService, Service } from './client.service';
import { MatDialog } from '@angular/material/dialog';
import { MatBottomSheet } from '@angular/material/bottom-sheet';
import { Router } from '@angular/router';
import { RolePermissionService } from './role-permission.service';

@Injectable({
  providedIn: 'root',
})
export class AuthorizationService {
  constructor(
    private storage: StorageService,
    private dialog: MatDialog,
    private bottomSeet: MatBottomSheet,
    private router: Router,
    private client: ClientService,
    private rolePermission: RolePermissionService,
  ) {}

  public logOut(navigateToLogin) {
    this.dialog.closeAll();
    this.bottomSeet.dismiss();
    this.client.get('user/SignOut', { service: Service.Auth }).subscribe();
    this.storage.deleteAccessToken();
    this.storage.deleteUserRole();
    this.rolePermission.deleteRolePermission();
    if (navigateToLogin) {
      this.router.navigate(['/login']);
    }
  }
}
